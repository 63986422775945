<template>
  <div class="auditPageBox">
    <el-dialog title="审核" width="35%" :visible.sync="dialogAuditFormVisible" :close-on-click-modal="false">
      <el-form ref="audit" :model="auditForm" label-width="80px">
        <el-form-item
          label="审核"
          prop="state"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' }
          ]"
        >
          <el-select v-model="auditForm.state" placeholder="请选择">
            <el-option
              v-for="item in auditOptionItem"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @change="$forceUpdate()"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="auditForm.remark" type="textarea" maxlength="200" show-word-limit autocomplete="off" @input="$forceUpdate()" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain class="widen-button" @click="dialogAuditFormVisible = false">
          取 消
        </el-button>
        <el-button :loading="$store.state.loading" size="small" class="widen-button" type="primary" @click="determineAudit('audit')">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    auditOptionItem: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      auditForm: {},
      dialogAuditFormVisible: false
    }
  },
  watch: {
    dialogAuditFormVisible: {
      handler(newVal) {
        if (newVal) this.auditForm = {}
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    determineAudit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('reviewSubmitted', this.auditForm)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.auditPageBox {
  .el-form {
    .el-select {
      width: 100%;
    }

  }
  .dialog-footer{
      text-align: center!important;
    }
}
</style>
